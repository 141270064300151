//variables
//var variable = $(".element").funkce();

//onLoad
$(function() {
	var owlOptions = {
		items: 1,
		loop: true,
		nav: true,
		dots: false,
		mouseDrag: false,
		touchDrag: false,
		pullDrag: false,
		freeDrag: false,
		autoplay: true,
		autoplayTimeout: 8000,
		//autoplayHoverPause: true,
		animateIn: 'fadeIn',
		animateOut: 'fadeOut',
		autoHeight: true,
		onInitialized: owlInitCallback
	};

    var owl = $("#main-carousel").owlCarousel(owlOptions);
	function owlInitCallback(event) {
		if(owlOptions.autoHeight) {
			var element = $(event.target);
			var item      = element.find(".item");
			$.each(item, function() {
				var item = $(this);
				var itemNewImage;
				var itemImage = item.find('img').attr('src');
				if (itemImage.length) {
				    itemNewImage = new Image();

				    $(itemNewImage).load(function () {
				    	$('.owl-height').css('height', itemNewImage.height/itemNewImage.width*100+'%');
						item.css({
		    				'background-size': 'auto 100%',    				
    						'background-position': 'center top',
		    				'filter': 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+itemImage+'",sizingMethod="scale");',
		    				'-ms-filter': 'progid:DXImageTransform.Microsoft.AlphaImageLoader(src="'+itemImage+'",sizingMethod="scale");'
						});
				    });
				    itemNewImage.src = itemImage;
				}
			});
		}
	}
});

//onLoad
$(function() {
	var cookiesEvent = jQuery.Event( "akon.event.cookies.accept" );
	var cookiesHeight = $(".cookies").outerHeight(true);
	var bodyPaddingOld = parseFloat($("body").css("padding-top"));
	$(".cookies").css({"margin-top": -cookiesHeight});

	function cookieShow() {
		$(".cookies").css({"opacity": 1});
		$(".cookies").animate({"margin-top": 0}, { duration: 250, queue: false });
		$("body").animate({"padding-top": bodyPaddingOld+cookiesHeight}, { duration: 250, queue: false });
		$(".affix").animate({"top": bodyPaddingOld+cookiesHeight}, { duration: 250, queue: false });
	}

	function cookieHide() {
		$(".cookies").animate({"margin-top": -cookiesHeight}, { duration: 250, queue: false }, function() {
			$(".cookies").css({"opacity": 0});
		});
		$("body").animate({"padding-top": bodyPaddingOld}, { duration: 250, queue: false });
		$(".affix").animate({"top": 0}, { duration: 250, queue: false });
	}

	$("#cookies-accept").on("click",function(){
		$(document).trigger( cookiesEvent );
	});

	if(Cookies.get('akon.cookies') != "true") {
		setTimeout(function () {
			cookieShow();
		}, 750);
		
	} 

	$(document).on("akon.event.cookies.accept", function(){
		Cookies.set('akon.cookies', "true");
		cookieHide();
	});
});

//onResize
$( window ).resize(function() {

});